$section-font-size: .85rem;

section.pipeline {
    font-size: $section-font-size;
    line-height: 1.1rem;
}

#pipeline_table {
    border: 1px solid $solid-color !important;
    height: 70vh;
    overflow-y: scroll !important;
    td {
        a { color: $solid-color }
    }
    thead {
        .multiselect.btn {
            padding: 0 .3rem;
            font-size: $section-font-size;
            border: 1px $solid-color solid;
            color: $solid-color;
            background-color: #fff;
        }
        .input-search {
            text-align: center;
            border: 1px $solid-color solid;
            color: $solid-color;
            padding: .1rem;
            border-radius: .25rem;
            width: 100%;
            &::placeholder {
              color: $solid-color;
            }
        }
        tr + tr th {
            padding-right: .9rem;
            border-bottom: 1px;
        }
    }

    .header-clean {
      border-top: none;
    }

    .header-column {
      text-align: center;
      text-transform: uppercase;
    }
}

.multiselect-container {
    font-size: 0.95rem;
    line-height: 1.1rem;
    color: $gray-700;
    .filter {
        margin-bottom: .4rem;
        .multiselect-search {
            font-size: $section-font-size;
            padding: .3rem;
        }
    }
    .checkbox {
        font-weight: normal;
        margin-bottom: .4rem;
        color: $solid-color !important;
        input {
            margin-right: .3rem;
        }
    }
}

// Overwrite Data Table entries btn
.dataTables_length {
  text-transform: capitalize;
  select {
    color: $solid-color;
    border-radius: 0 !important;
    border-color: $solid-color;
    background-color: white;
    &:disabled {
      background-color: white;
      color: $solid-color;
    }
  }
}

// Overwrite Data Table Search
.dataTables_filter {
  border-color: $solid-color;
  input[type="search"] {
    border-radius: 15px;
    border-color: $solid-color;
  }
}

// Overwrite Data Table pagination
.dataTables_paginate {
  a {
    color: $solid-color !important;
  }
  .page-item.active .page-link {
    background-color: $action-color !important;
    border-color: $action-color !important;
    color: #fff !important;
  }
}

#log-table_paginate .pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end;
  a {
    color: $action-color !important;
  }
  .active a {
    background-color: $action-color !important;
    border-color: $action-color !important;
    color: #fff !important;
  }
}

#log-table_info {
  display: none;
}

// Btn filters
.span-filter {
  background-color: $action-color;
  color: #fff;
  padding: 5px;
  border-radius: 7px;
  border: none;
  margin-left: 3px;
  a {
    color: #fff;
    padding-left: 7px;
  }
}

.export-btn {
  background-color: $solid-color;
  color: #fff;
  padding: 5px;
  border-radius: 7px;
  border: none;
  margin-left: 3px;
  a {
    color: #fff;
    padding-left: 7px;
  }
}

.action-row {
  border-bottom: 1px solid #ccc;
  padding-top: 3px;
  .small {
    font-size: 75% !important;
  }
}

.pipeline-row {
  padding: 3px;
  min-height: 30px;
  overflow: auto;
  &:not(:last-child) {
    border-bottom: 1px solid #2B3E50;
  }
}

.kona-search-clean {
  position: absolute;
  right: 15px;
  top: 8px;
}

.table-pipeline {
  border: 0 !important;
}

#pipeline_table_filter input {
  width: 330px;
}

.prescription-top-header {
  background-color: #748C43;
  color: #fff;
}
.information-top-header {
  background-color: #006699;
  color: #fff;
}
.action-top-header {
  background-color: #903C3A;
  color: #fff;
}

.prescription-header {
  background-color: #E3E8D9;
}
.information-header {
  background-color: #D4E3EB;
}
.action-header {
  background-color: #E9D8D8;
}

#id_total_area::placeholder, #total-area-multi-field::placeholder {
  color: #848484;
  font-style: italic;
}

.vex.vex-theme-top .vex-dialog-button.vex-dialog-button-primary {
  background: #069 !important;
  border-color: #069 !important;
  animation: none !important;
}

.vex.vex-theme-top .vex-dialog-button.vex-dialog-button-primary:hover {
  background: #6B6B6B !important;
  border-color: #6B6B6B !important;
  animation: none !important;
}
input[type="search"]::-ms-clear { display: none; width : 0; height: 0; }
input[type="search"]::-ms-reveal { display: none; width : 0; height: 0; }
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

.pipeline-nav a {
  border-bottom: 2px solid #9d9695;
  color: #9d9695;
  &.active {
    border-bottom: 2px solid #000;
    color: #000;
  }
}
