// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

.section__home {
  .search-form {
    .input-group-text{
      border-radius: 7px 0 0 7px;
      border-color: $action-color;
    }
    .input-group-addon {
      background-color: transparent;
      border-right: none;
      padding: .5rem;
    }
    .form-control {
      border-left: none;
      padding: .5rem;
    }
    .easy-autocomplete {
      flex: 1;
      input.form-control {
        box-shadow: none;
        border-radius: 0;
        border-color: $action-color;
        &:focus {
          border-color: $action-color;
        }
      }
      .eac-item {
        font-size: 1.2rem;
        .icon {
          margin-right: 0.5rem;
        }
      }
    }
  }

  .stores-table {
    vertical-align: top;
    font-size: 16px;
    th, td {
      a {
        color: $solid-color;
      }
      &:nth-child(1) {
        width: 138px;
      }
      &:nth-child(2) {
        width: 87px;
        text-align: center;
      }

      &:nth-child(3) {
        a {
          color: $solid-color;
          text-decoration: underline;
        }
      }

    }

    &__link {
      span:first-child {
        text-decoration: underline;
      }
      span:nth-child(2) {
        margin-left: 5px;
      }
    }

    caption {
      caption-side: top;
      color: $solid-color;
    }

    @include media-breakpoint-up(lg) {

      display: flex;
      flex-flow: column;
      height: 100%;

      thead {
        /* head takes the height it requires,
        and it's not scaled when table is resized */
flex: 0 0 auto;
width: 100%;
}
tbody {
/* body takes all the remaining available space */
flex: 1 1 auto;
display: block;
overflow-y: scroll;
}
tbody tr {
width: 100%;
}
thead,
tbody tr {
display: table;
table-layout: fixed;
}
}
}

#map-canvas {
height: 400px;
}

@include media-breakpoint-up(lg) {
height: calc(100vh - #{$navbar-height});

#map-canvas {
flex: auto;
}
}
}
