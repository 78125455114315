.note-log {
  .note-left-box, .note-right-box {
    display: inline-block;
  }
  .note-left-box {
    width: 25px;
    float: left;
  }
  .note-info {
    margin: 0;
    .profile-icon {
      color: $kona-light-label;
      background-color: $kona-solid;
      display: inline-block;
    }
  }
  .note-date {
    margin: 0 0 10px 0;
    font-size: 12px;
    color: $kona-light-label;
    border-bottom: 1px solid $kona-light-label;
    a {
      color: #C5C1C0;
    }
  }
}
