.form-notes {
    .btn {
        line-height: 1;
    }
    .notes {
        border-color: transparent;
        box-shadow: none;
    }
}

.input--transparent {
    background-color: transparent;
    border-color: white;
}

.input--important {
    min-width: 150px;
}

#location-mw-wrap {
    width: 100%;
    min-width: 320px;
    .mw-btn {
        color: white;
    }
    .mw-coordinates-overlay {
        width: 200px;
    }
    .mw-adress-input-wrap {
        display: inline-block;
        width: 100%;
        margin-top: 5px;
    }
    .pull-right {
        float: none;
    }
}

.checkbox-dropdown {
    .custom-control-label {
        &:before, &:after {
            width: 1.25rem;
            height: 1.25rem;
            top: 0;
            background-color: transparent !important;
            border-radius: 0;
            border: .15rem $gray-800 solid;
        }
    }

    .custom-control-input:checked ~ .custom-control-label {
        &:after {
            background-size: .9rem .9rem;
            background-position: center center;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23343a40' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E")
        }
    }

    .custom-control-input:indeterminate ~ .custom-control-label {
        &::after {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23343a40' d='M0 2h4'/%3E%3C/svg%3E");
        }
    }

    .dropdown-item.custom-control {
        .custom-control-label {
            padding-left: 1rem;
            &:before, &:after {
                left: -0.75rem;
            }
        }
    }
}

// Select2
.select2-container {
    width: 100% !important;
}

.kona-field {
  color: $solid-color;
  border-radius: 0;
  border-color: $solid-color;
  background-color: white;
  height: 36px;
  &:disabled {
    background-color: white;
    color: $solid-color;
  }
}

.kona-input-search {
  border-radius: 15px;
  font-size: .875rem;
  line-height: 1.5;
  border: 1px solid $solid-color !important;
}

.kona-search-clean {
  color: $solid-color;
  &:hover {
    color: $solid-color;
  }
}

.input-comment {
  border: 1px solid $solid-color !important;
  border-radius: 5px;
  color: $solid-color;

  &::placeholder {
    color: $solid-color;
  }

  &::-ms-input-placeholder {
    color: $solid-color;
  }

  &:-ms-input-placeholder {
    color: $solid-color;
  }

}

.kona-color-form {
  input, select {
    color: #fff;
  }

  .multiselect-input {
    border-radius: .2rem;
    color: #fff;
  }
}

// Special format for alcohol field in site request
.request-alcohol {
  list-style: none;
  padding-left: 0;
  li {
    display: inline;
    margin-right: 5px;
  }
}

.progress_summary-checkbox {
  a {
    margin-bottom: .5rem;
    display: inline-block;
  }

}

#site-attributes-form {
  label {
    margin-bottom: 0;
  }
  .double-size-field-label {
    padding-top: 6px !important;
    vertical-align: top !important;
  }
  .table-sm th, .table-sm td {
    padding: 1px;
  }
  .form-control {
    height: 29px;
    font-size: 13px;
  }
  .multiselect-readonly, .form-control[readonly] {
    background-color: lighten($light-color, 10%);
    opacity: 1;
    &:focus, &:active {
      color: $solid-color;
    }
  }
  .attr-alcohol-readonly {
    background-color: lighten($light-color, 10%);
  }
  .exclusive-btn {
    margin-top: -17px;
  }
  .multiselect-input button {
    color: #fff;
    border-color: $solid-color;
    padding: 2px 0 !important;
    font-size: 13px !important;
  }
  #id_attr-alcohol, #id_attr-exclusives {
    margin-bottom: 0;
    padding: 4px 0 2px 3px;
    border: 1px solid $solid-color !important;
    border-radius: 3.2px;
    text-align: left;
    label {
      line-height: 0.5;
      position: relative;
      font-size: 14px;
      padding-left: 17px;
      input {
        position: absolute;
        left: 0;
        top: -3px;
      }
    }
  }
  #id_attr-exclusives {
    li {
      display: inline-block;
      text-align: left;
    }
  }
  #id_attr-alcohol {
    height: 29px;
  }
}

.select2-selection--single {
  height: calc(2.25rem + 2px) !important;
  border: 1px solid #ced4da !important;
}

#neighborhood-description-textarea {
  height: 140px;
}

#neighborhood-description-textarea[readonly] {
  background-color: #fff;
}

.loi-users-title {
  display: block;
  padding-left: 15px;
  text-indent: -15px;

  input {
    width: 13px;
    height: 13px;
    padding: 0;
    margin:0;
    margin-left: 9px;
    vertical-align: bottom;
    position: relative;
    top: -4px;
  }
}
