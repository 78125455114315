/**
 * Basic typography style for copy text
 */

@font-face {
  font-family: 'Century Gothic';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/century_gothic.ttf') format('truetype')
}

@font-face {
  font-family: 'Century Gothic Bold';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/century_gothic_bold.ttf') format('truetype')
}

@font-face {
  font-family: 'PT Sans Narrow';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/PTSansNarrow-Regular.ttf') format('truetype')
}

@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/PTSans-Regular.ttf') format('truetype')
}

body {
  color: $solid-color;
  font-family: 'PT Sans Narrow';
  font-size: 10pt;
}

.main-navbar {
  font-family: 'PT Sans';
}

.century-gothic-font {
  font-family: 'Century Gothic';
}

.century-gothic-bold-font {
  font-family: 'Century Gothic Bold';
}

// Generate text-name for our theme colors
@each $key, $value in $local-colors {
    /* #{$key} */
    .text-#{$key} {
        color: $value !important;
    }
}

.strong {
    font-weight: bold !important;
}

.small {
    font-size: 80%;
}
