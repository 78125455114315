// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------


/// Main brand color
/// @type Color
$primary: #1A2930 !default;


// Theme Colors v1
$jungle-mist: #B9CFDA;
$jelly-bean: #437B91;
$default: #343a40;

// Kona Colors v2
$kona-purple: #52668F;
$kona-gray: #DFDFDF;
$kona-orange: #F6882F;
$kona-beige: #DCC7AA;

// Kona Colors v3
$kona-dark: #1A2930;
$kona-solid: #2B3E50;
$kona-label: #F7CE3E;
$kona-light-label: #C5C1C0;

// Kona Colors v4
$solid-color: #1A2930;
$light-color: #6B6B6B;
$action-color: #006699;
$label-color: #C5C1C0;

$local-colors: () !default;
$local-colors: map-merge((
    "default":        $default,
    "jungle-mist":    $jungle-mist,
    "jelly-bean":     $jelly-bean,
    "kona-orange":    $kona-orange,
    "kona-dark":      $kona-dark,
    "kona-label":     $kona-label,
    "kona-light-label":     $kona-light-label,
    "kona-solid":     $kona-solid,
    "solid-color":    $solid-color,
    "action-color":   $action-color,
    "label-color":    $label-color,
    "light-color":    $light-color
), $local-colors);

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/static/' !default;

$navbar-height: 56px;

$grid-gutter-width: 15px;
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1300px
);
