// Generates tooltips corresponding to colors
.tooltip {
    @each $key, $value in $theme-colors {
        &.tooltip-#{$key} {
            .tooltip-inner {
                background-color: $value;
            }
            &[x-placement^="top"] {
                .arrow:before {
                    border-top-color: $value;
                }
            }
            &[x-placement^="right"] {
                .arrow:before {
                    border-right-color: $value;
                }
            }
            &[x-placement^="bottom"] {
                .arrow:before {
                    border-bottom-color: $value;
                }
            }
            &[x-placement^="left"] {
                .arrow:before {
                    border-left-color: $value;
                }
            }
        }
    }
}