// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.btn-primary {
  background-color: $action-color;
  color: #FFF;
  border-color: $action-color;
  &:hover, &:active {
    color: #fff !important;
    background-color: $light-color !important;
    border-color: $light-color !important;
  }
}

.btn-dark {
  background-color: $kona-dark;
  color: $kona-light-label;
  border-color: $kona-label;
}

.btn-gray {
    background-color: $gray-500;

    @include on-event {
        background-color: $gray-600;
    }
}

.btn-jelly-bean {
    background-color: $jelly-bean;
    color: $jungle-mist;

    @include on-event {
        background-color: lighten($jelly-bean, 20%);
        color: white;
    }
}

.btn-rounded {
  border-radius: 15px;
}

.request-action {
  color: #fff !important;
}
