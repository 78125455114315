.kona-box-scroll {
  max-height: 250px;
  overflow: auto;
}

.card {
  border-radius: 0;
  border-color: $solid-color;
  border-width: 2px;
  .card-header {
    background-color: $solid-color;
    border-radius: 0;
    color: #fff;
  }
}

.prescription-list {
  line-height: 2.2;
}

.comparison-number {
  font-size: 24px;
  font-weight: bold;
  color: $kona-solid;
}

.comparison-list {
  list-style: none;
  padding-left: 0;
}

#neighborhood-description-card {
  height: 90px;
}
