.activity-log {
  .activity-info {
    margin: 0;
    .profile-icon {
      color: $kona-light-label;
      background-color: $kona-solid;
      display: inline-block;
    }
  }
  .activity-date {
    margin: 12px 0 10px 0;
    font-size: 12px;
    color: $kona-light-label;
    border-bottom: 1px solid $kona-light-label;
  }
}
