.section__store_detail {
  font-size: 14px !important;
}

.left-sidebar {
  background-color: $light-color;
  color: #fff;
  position: relative;
}

.btn-dark {
  background-color: $solid-color;
  color: #fff;
  border-color: $solid-color;
}

.left-sidebar-table {
  td:first-child {
    width: 110px;
  }
  font-size: 13px;
  font-family: 'PT Sans Narrow';
  color: #fff;
  input, select {
    font-size: 13px;
    border-color: $solid-color;
    padding: 2px 8px 0 8px !important;
  }
  select {
    height: 24px !important;
  }
  a {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
}
.site-attributes {
  .prepend {
    input {
      border-left: 0;
      border-radius: 0 3.2px 3.2px 0 !important;
    }
    .input-group-text {
      padding: 0 0 0 2px !important;
    }
  }
  .append {
    input {
      border-right: 0;
      border-radius: 3.2px 0 0 3.2px !important;
    }
    .input-group-text {
      padding: 0 10px 0 2px !important;
    }
  }
  .input-group-text {
    background-color: lighten($light-color, 10%);
    color: #fff;
    border-color: $solid-color;
  }
  .btn {
    padding: 2px 6px 2px 6px;
  }
  .btn-top {
    margin-top: -16px;
  }
  .edit-description-btn, .submit-description-btn {
    margin-top: -92px;
  }
  .table {
    td {
      vertical-align: middle;
    }
    .input--transparent {
      text-align: center;
    }
    .double-line-label {
      line-height: 13px;
    }
    .input-attr-textarea {
      max-height: 43px !important;
      min-height: 29px !important;
      border-color: $solid-color;
      text-align: left;
      overflow: auto !important;
    }
    #id_attr-description {
      max-height: 120px !important;
      min-height: 120px !important;
    }
    #id_attr-multi_level_total_area {
      height: 65px !important;
      border-color: $solid-color;
      text-align: left;
      color: #fff;
    }
    .btn-total-area-multi-active {
      margin-top: -74px;
    }
    .input-attr-textarea[readonly], #id_attr-multi_level_total_area[readonly] {
      color: #fff;
    }
    .input-attr-textarea:focus, #id_attr-multi_level_total_area:focus {
      color: #000;
    }
    .input-dropdown {
      background-color: #858585;
      opacity: 1;
      height: 100%;
      min-height: 29px;
      max-height: 45px;
      font-size: 13px;
      border: 1px solid $solid-color;
      line-height: 1.5;
      border-radius: .2rem;
      padding: 4px 8px 0 8px !important;
      vertical-align: middle;
    }
    .description-input-dropdown {
      min-height: 120px !important;
      max-height: 120px !important;
    }
    .dropdown-menu {
      width: 270px;
      border-color: $solid-color;
      z-index: 5000;
      .dropdown-header {
        color: #FFF;
        background-color: $solid-color;
      }
    }
  }
}

.tabs-content {
  .nav-item {
    font-size: 1rem;
    padding: 0.5rem;

    @include media-breakpoint-up(lg) {
      font-size: 1.2rem;
    }
  }
  thead td {
    vertical-align: middle;
  }
}

.profile-icon {
  display: block;
  width: 25px;
  height: 25px;
  font-size: 0.75rem;
  line-height: 25px;
  text-align: center;
  background-color: $gray-700;
  border-radius: 50%;
}

.multiselect-container.dropdown-menu {
  padding: .3rem .5rem;
  .checkbox {
    width: 100%;
  }
}

#site-detail-form {
  input, select {
    max-width: 260px;
  }
  td {
    padding: 1px;
  }
  #id_dtl-status {
    height: 31px !important;
    min-width: 170px !important;
    padding: 4px 15px 5px 15px!important;
    font-size: 14px;
  }
}

.pac-container {
  background-color: #FFF;
  z-index: 1060;
  position: fixed;
  display: inline-block;
  float: left;
}

#site-detail-edit-btn {
  margin-bottom: -45px;
  &:hover {
    background-color: $solid-color !important;
  }
}

#site-detail-save-btn {
  margin-bottom: -45px;
  &:hover {
    background-color: $solid-color !important;
  }
}

#note-action-box {
  height: 247px;

  #note-box {
    overflow: scroll;
  }

  #action-box {
    overflow: scroll;
  }
}

.input-row td {
  border-bottom: 0 !important;
}

.btn-row td {
  border-top: 0 !important;
}

#action-form {
  max-height: 297px;
  overflow-y: auto;
  thead tr:first-child th {
    background-color: $solid-color;
    color: #fff;
    text-align: center;
  }

  tbody tr:first-child td {
    border-bottom: 0 !important;
  }

  tbody tr:nth-child(2) td {
    border-top: 0 !important;
  }

  textarea {
    border-color: $solid-color;
  }

  .action-date {
    margin: 0 0 10px 0;
    font-size: 12px;
    color: #C5C1C0;
    a {
      color: #C5C1C0 !important;
    }
  }
}

#contact-container {
  overflow-y: auto;
  height: 335px;

  .contact-div {
    height: 185px;
  }
}

label.error {
  background-color: #ced4da;
  padding: 5px;
  margin-top: 5px;
}

#dropbox-form {
  .file {
    cursor: pointer;
    &:hover {
      background-color: lighten(#e3f2ff, 3%);
    }
    span {
      color: #92ceff;
      font-size: 25px;
    }
    label {
      padding-bottom: 10px;
      vertical-align: middle;
      cursor: pointer;
      user-select: none;
    }
  }
  .file.active {
    background-color: #e3f2ff;
    user-select: none;
  }
  .file-unlinked {
    span {
      color: #adb5bd;
      font-size: 20px;
    }
  }
  input[type=radio] {
    display: none;
  }
}

#dropbox-files {
  .tab-content {
    background-color: #edeff0;
    border: 1px;
    border-color: #495057;
    height: 345px;
  }
  .nav-item {
    font-size: 14px !important;
    padding: 0 !important;
  }
  .nav-tabs {
    border-bottom: 0;
  }
  .nav-link {
    background-color: darken(#edeff0, 10%);
    color: #2B3E50;
  }
  .nav-link.active {
    font-weight: bold;
    background-color: #edeff0;
    border-bottom: 0;
  }
  .db-folder {
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    .fa-folder {
      color: #92ceff;
    }
    &:hover {
      background-color: lighten(#e3f2ff, 3%);
    }
  }
  .db-file {
    border-bottom: 1px solid #ccc;
    user-select: none;
    .fa-file-pdf {
      color: red;
    }
    .fa-file-word {
      color: blue;
    }
    .fa-file-excel {
      color: green
    }
    .fa-file {
      color: white;
    }
  }
  .icon {
    font-size: 14px;
  }
  .file-name {
    padding-bottom: 10px;
    vertical-align: middle;
  }
}

#commissions-form {
  input {
    text-align: center;
  }

}

/* Progress Widget */
$active-color: #006699;
$last-active: #2c4551;
$grey: #c5c1c0;

.k-timeline-container {
  width: 700px;
  overflow-x: auto;
  width: 100%;
  word-wrap: initial;
  height: 225px;
}
.k-timeline{
  margin: auto;
  //background:#eee;
  height:140px;
  padding-top:93px;
  width: 770px;
  .true-circle {
    font-size: 67px;
    display: inline-block;
    font-family: arial;
  }
  .timeline-element{
    display:block;
    float:left;
    width:77px;
    height:70px;
    text-align:center;
    font-size:11px;
    position: relative;
    .timeline-selector {
      font-size:18px;
      cursor: pointer;
    }
    &.dropped{
      margin-top:-75px;
      width:40px;
      margin-right:-10px;
      .timeline-selector.false{
        color:$grey;
        margin-top:5px;
        &:before{
          transform: rotate(-58deg);
          top: 35px;
          left: -23px;
          width: 41px;
          height: 6px;
        }
      }
      .timeline-selector.true{
        margin-top:5px;
        &:before{
          transform: rotate(-58deg);
          top: 35px;
          left: -23px;
          width: 41px;
          height: 6px;
        }
      }
      .timeline-selector.current-stage {
        padding-left: 0px !important;
      }
    }

    &.before-dropped{
      .timeline-selector.last-stage {
        &:after{
          content:" ";
          position:absolute;
          width:30px;
          height:7px;
          background: $active-color;
          top: 10px;
          left: 20px;
          z-index:-1;
        }
      }
      .timeline-selector.true{
        &.first:before{
          display:none !important;
        }
      }
    }
    &.after-dropped{
      .timeline-selector.true{
        &:before{
          content:" ";
          position:absolute;
          width:60px;
          height:7px;
          background: $active-color;
          top: 10px;
          left: -53px;
          //right:-20px;
          z-index:-1;
        }
        &.first:before{
          display:none !important;
        }
      }
    }
    .timeline-selector{
      background: $grey;
      width: 26px;
      height: 26px;
      margin:0 auto 5px auto;
      line-height:31px;
      border-radius:35px;
      position:relative;
      cursor:pointer;
      &:hover{
        background: $grey - 10;
      }
      &.disabled{
        background: $grey;
        cursor:default;
      }
    }
    .timeline-selector.true{
      // color: $active-color;
      background-color: $active-color;
      &:before{
        content:" ";
        position:absolute;
        width:80px;
        height:7px;
        background: $active-color;
        top: 10px;
        left: -60px;
        z-index:-2;
      }
      &.first:before{
        display:none !important;
      }
    }
    .timeline-selector.false{
      color:$grey;
      &:before{
        content:" ";
        position:absolute;
        width:80px;
        height:7px;
        background:$grey;
        top: 10px;
        left: -60px;
        z-index:-2;
      }
      &.first:before{
        display:none !important;
      }
    }
    .timeline-label{
      color:$grey;
      font-weight:bold;
    }
    .timeline-label-active {
      color: $solid-color;
    }
    .timeline-label-bottom {
      font-size: 12px;
      line-height: 1.3;
    }
    .timeline-sub-label{
      height: 11px;
      font-size: 9px;
      color: $solid-color;
    }
    .timeline-sub-label-bottom {
      position: absolute;
      bottom: 0px;
      width: 77px;
      height: 10px;
      font-size: 10px;
    }
    .timeline-selector.current-stage {
      background-color: $solid-color;
      background-image: url("/static/images/current-stage.png");
      background-position: center;
      background-repeat: no-repeat;
      color: $active-color;
      //padding-left: 1px;
    }
  }
}

.pursue-checkbox {
  padding-left: 35px;
}

.pursue-checkbox label:hover {
  text-decoration: underline;
  cursor: pointer;
}

.pursue-checkbox label, #relocation-of-label {
  font-size: 15px;
}

.clearfix{
  clear:both;
}

.progress-card {
  .card-header {
    padding: 8px 20px 4px 23px;
  }
}

.box-horizontal-scroll {
  min-width: 485px;
  overflow: scroll;
}

.kona-tabs-content {
  height: 396px;
}

.btn-folder-url {
  background-color: #edeff0;
  border-color: #edeff0;
}

#note-box-scroll {
  height: 238px;
  overflow: auto;
}

#activity-box-scroll {
  height: 300px;
  overflow: auto;
}

#relocation-list {
  list-style-type: none;
  font-size: 13px;
  a {
    color: #FFF;
  }
}

#expansionsModal, #extensionsModal {
  label, p {
    font-size: 15px;
  }
}

.expansion-inline {
  .form-check-input:disabled ~ .form-check-label {
    color: #000 !important;
  }
}

.expansion-right-border, .extension-right-border {
  border-right: 2px solid #ccc;
}
.expansion-inline-div, .extension-inline-div {
  .delete-row {
    display: none;
  }
}

.extension-editable-field, .extension-remaining-other-field, .extension-proposed-other-field, .expansion-other-field {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.dark-header-modal {
  .modal-header {
    color: #fff;
    background-color: $kona-dark;
  }
  .modal-content {
    border-radius: 10px;
  }
}

.modal-extra-width {
  max-width: 1250px;
}

.btn-vertical {
  vertical-align:top;
  height:30px;
  transform:rotate(-90deg);
  -ms-transform:rotate(-90deg); /* IE 9 */
  -moz-transform:rotate(-90deg); /* Firefox */
  -webkit-transform:rotate(-90deg); /* Safari and Chrome */
  -o-transform:rotate(-90deg);
  background-color: $action-color;
  background-image: url("/static/images/expansion-arrow.png");
  background-position: right;
  background-repeat: no-repeat;
  color: #fff;
  padding-left: 8px;
  padding-top: 2px;
  border: none;
  border-radius: 0 0 12px 12px;
  cursor: pointer;
  margin-right: -33px;
  position: absolute;
  right: -39px;
  z-index: 1000;
  text-transform: uppercase;
  padding-top: 0;
  padding-bottom: 2px;
  font-size: 17px;
  text-align: left;
}

#btn-expansion-modal {
  width:115px;
  top: 165px;
}

#btn-extension-modal {
  width:115px;
  top: 42px;
}

.expansion-number-counter, .extension-number-counter {
  color: #fff;
  background-color: #069;
  padding: 4px 10px 3px 10px;
  border-radius: 50px;
  text-align: center;
  margin-right: 20px;
}

#gallery-container {
  height: 290px;
  .row {
    height: 290px;
  }
}

#map-gallery-content {
  border: 2px solid $kona-dark;
}

#map-gallery-buttons-container {
  height: 184px;
}

#tab-map-btn {
  padding-top: 13px;
  padding-bottom: 13px;
}

.map-gallery-btn {
  color: #C5C1C0;
  background-color: #1A2930;
  &:hover {
    color: #fff;
  }
}

.map-gallery-btn.active {
  color: #fff;
}

#tab-gallery-btn {
  line-height: 1;
}

.dropbox-modal-container {
  width: 755px;
  margin-left: auto;
  margin-right: auto;
}

.dropbox-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.gm-style .gm-style-iw {
  min-height: 170px !important;
  z-index:4000;
}

.gm-style .gm-style-iw-d {
  min-height: 170px !important;
  z-index:4000;
}

#title-edition-field {
  width: 80% !important;
}

#panel-map-canvas {
  height: 290px;
}

#modal-map-canvas {
  height: 560px;
}

@media (min-width: 800px) {
  .dropbox-container {
    max-width: 330px;
  }
}
@media (min-width: 900px) {
  .dropbox-container {
    max-width: 430px;
  }
}
@media (min-width: 1000px) {
  .dropbox-container {
    max-width: 530px;
  }
}
@media (min-width: 1100px) {
  .dropbox-container {
    max-width: 630px;
  }
}
@media (min-width: 1200px) {
  .dropbox-container {
    max-width: 730px;
  }
}

@media (min-width: 1300px) {
  .dropbox-container {
    max-width: 830px;
  }
}

@media (min-width: 1400px) {
  .dropbox-container {
    max-width: 980px;
  }
}

@media (min-width: 1500px) {
  .dropbox-container {
    max-width: 1085px;
  }
}

@media (min-width: 1600px) {
  .dropbox-container {
    max-width: 1180px;
  }
}

@media (min-width: 1700px) {
  .dropbox-container {
    max-width: 1285px;
  }
}

@media (min-width: 1800px) {
  .dropbox-container {
    max-width: 1380px;
  }
}

@media (min-width: 1900px) {
  .dropbox-container {
    max-width: 1485px;
  }
}

