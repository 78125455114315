// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.header {
    font: 'PT Sans';
    .main-navbar {
        background-color: $solid-color;
        height: 55px;
        .icon {
            color: $gray-300;
        }
        .navbar-toggler {
            .icon {
                color: $gray-300;
                &:before {
                    display: block;
                }
            }
        }
        .nav-item {
            border-top: 1px rgba($gray-300, 0.4) solid;
            transition: background-color ease-in .3s;
            .nav-link {
              color: $label-color;
              &.active {
                color: #fff;
                border-bottom: 1px solid #fff;
                padding-bottom: 3px;
              }
            }
            @include media-breakpoint-up(lg) {
                border-top: 0;
            }
        }
    }

    form {
      width: 30%;
    }

    .easy-autocomplete {
      width: 80%;
    }

    .dropdown-menu {
      border-radius: 0;
      background-color: $solid-color;
      .dropdown-item {
        color: $label-color;
        &.active {
          color: #fff;
        }
        &:hover {
          background-color: $solid-color !important;
        }
      }
    }
}
