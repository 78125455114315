.kona-tabs {
  border: 2px solid $solid-color;
  nav {
    background-color: $solid-color;
    border-radius: 0;

    a {
      color: $label-color;
      padding-bottom: 0 !important;
      margin-bottom: 8px !important;
    }

    a.active {
      color: #fff;
      border-bottom: 1px solid #fff;
    }
  }
}
