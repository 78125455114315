#broker-document-section {
  #id_attachment, #id_name {
    width: 100%;
  }

  #broker-document-table-box {
    overflow-y: auto;
    height: 120px;
  }
}

.broker-fixed-card {
  height: 250px;
}

.broker-site-card {
  height: 250px;
  .sites-table-dropdown {
    width: 450px;
  }
  .dropdown-menu-right {
    transform: translate3d(-200px, 17px, 0px) !important;
  }
}

.widget-title {
  height: 79px;
}

.widget-number {
  p {
    margin-bottom: 0 !important;
  }
  border-bottom: 2px solid $action-color;
  margin-bottom: 4px;
}

.right-vertical-border {
  border-right: 2px solid $action-color;
}

.bottom-vertical-border {
  width: 100%;
  border-bottom: 2px solid $action-color;
  margin-top: 15px;
  margin-bottom: 15px;
}

.broker-modal {
  width: 90%;
  max-width: 825px;
  label {
    display:none;
  }
  h2 {
    font-size: 14px;
  }
}
