.kona-map {
    min-height: 380px;
}

.kona-request-map {
  min-height: 500px;
}

.label-marker {
  font-size: 13px;
  font-weight: bold;
  color: #000;
  text-shadow:
     1px  1px     #fff,
    -1px  1px     #fff,
     1px -1px     #fff,
    -1px -1px     #fff,
     1px  1px 5px #555;
}
