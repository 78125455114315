// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------
.bg-blue {
    background-color: $blue;
    color: white;
}

.opacity-0 {
    opacity: 0;
}

.pointer {
    cursor:pointer;
}