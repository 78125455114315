#activity-section {
  .activity-log {
    max-height: 250px;
    overflow-y: scroll;
  }
}

.neighborhood-item-section {
  height: 240px;
  overflow-y: scroll;
}

.card {
  .card-header {
    padding: 8px 20px 4px 23px;
  }
}

.main-status-select {
  width: auto !important;
  padding: 0 !important;
}

.status-cell {
  width: 70px;
}

.edit-status-cell {
  padding: 5px !important;
}

.neighborhood-title {
  font-family: 'Century Gothic';
  font-size: 26px;
}

.neighborhood-info-table {
  font-family: 'PT Sans Narrow';
  font-size: 15px;
  overflow-x: visible !important;
}

#description-edit-btn, #description-save-btn, #market-edit-btn, #market-save-btn{
  font-size: 12px;
  padding-left: 5px;
}

#dropdown-message-description {
    transform: translate3d(5px, 35px, 0px)!important;
}

#description-readonly-field {
  p {
    margin-bottom: 0;
  }
}
