.table {
    &--horizontal-bordered {
        td, th {
            border-top: none;
            font-weight: 400;
            &:not(:last-child){
                border-right: .15rem solid;
            }
            @include media-breakpoint-up(lg) {
                white-space: normal;
            }
        }
        thead {
            th {
                padding: 0;
                border-bottom: .15rem solid;
                & > div {
                    margin: 0 .4rem .4rem .4rem;
                    padding: .15rem .4rem;
                    white-space: nowrap;
                }
                &:first-child {
                    & > div {
                        margin: 0 .4rem .4rem 0;
                    }
                }
                &:last-child {
                    & > div {
                        margin: 0 0 .4rem .4rem;
                    }
                }
            }
        }
        tbody {
            td {
                padding: .3rem .5rem;
            }
        }

        &--jungle-mist {
            color: $jelly-bean;
            thead {
                th > div {
                    color: $jungle-mist;
                    background-color: $jelly-bean;
                }
            }
        }
        &--jelly-bean {
            color: $jungle-mist;
            thead {
                th > div {
                    color: $jelly-bean;
                    background-color: $jungle-mist;
                }
            }
        }
    }
    &.table-striped {
        tbody tr {
            background-color: $jungle-mist;
            &:nth-of-type(odd) {
                background-color: darken($jungle-mist, 8%);
            }
        }
        td, th {
            border-top: none;
        }
    }
    &--no-bordered {
        td, th {
            border: none;
        }
    }
}

.kona-simple-table {
  thead th {
    border-bottom: 2px solid $kona-dark;
    border-top: 0;
  }
  a {
    color: $kona-label;
  }
}

.kona-clean-table {
  th {
    border: None;
  }

  td {
    border: None;
  }
}

.table-bordered {
  a { color: $kona-solid }
  border-color: $kona-solid !important;
  border-width: 0 1px 0 1px !important;
  thead th {
    border-bottom: 1px solid $kona-solid;
  }
  th, td {
    border: 1px solid $kona-solid;
  }
}
.input-note {
  border: 1px solid $kona-solid;
  color: $kona-solid;
  border-radius: 3.2px;
}

.table-search {
  td:first-child {
    width: 60px
  }
  td:nth-child(2) {
    position: relative;
    a {
      position: absolute;
      right: 15px;
      top: 11px;
    }
  }
  td:last-child {
    width: 140px;
  }
}

.table-wrapper {
  overflow-y: auto;
//  overflow-x: hidden !important;
  background-color: #fff;
}

.table-fixed {
  width: 99% !important;
  padding: 2px;
  thead {
    background-color: #fff;
  }
}

.table-section-header {
  thead {
    font-weight: bold;
    th {
      border-top: 0 !important;
      border-bottom: 1px solid $kona-solid;
    }
    tr:first-child th.header-section {
      border-left: 1px solid $kona-solid;
      border-top: 1px solid $kona-solid !important;
      border-right: 1px solid $kona-solid;
    }
    tr:last-child th {
      border-right: 1px solid $kona-solid;
      border-left: 1px solid $kona-solid;
      border-top: 1px solid $kona-solid;
    }
  }
  tbody {
    border: 1px solid $kona-solid;
    td {
      border: 1px solid $kona-solid;
    }
  }
}

.thead-dark {
  color: #fff;
  background-color: $kona-dark !important;
}

.rent-comparison-year-field {
  min-width: 65px;
}

.rent-comparison-rent-field {
  min-width: 70px;
}

.broker-mutilple-value {
  height: 17px;
}
