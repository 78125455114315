// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  font-size: 16px;
}

main.main {
    margin-top: $navbar-height - 1;
    min-height: calc(100vh - #{$navbar-height});
}

.container-md {
    max-width: 720px;
}
